import React from 'react';
import { Divider } from '@components/Grid';

import styles from './styles.module.scss';
import Img from 'gatsby-image';

const Hero = ({ image }) => {
    return (
        <section className={styles.heroWrapper}>
            <div className={styles.heroText}>
                <h1>A to B Tyres</h1>
                <p>
                    Creating a presence for a global marketplace that attracts ultra high networth
                    individuals & companies.
                </p>
            </div>
            <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
        </section>
    );
};

export default Hero;
