import React from 'react';
import Img from 'gatsby-image';
import { Col, Divider, Grid, Row } from '@components/Grid';

import styles from './styles.module.scss';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import ScrollTextWithImage from '@components/ScrollTextWithImage';
import { Link } from 'gatsby';
import Button from '@components/Button';

const Branding = ({ imageTop, imageSite, imageCar, imagePhones, imagePhotography }) => {
    return (
        <section className={styles.brandingWrapper}>
            <Grid>
                <Img fluid={imageTop} imgStyle={{ objectFit: 'cover' }} />
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more about our
                        <br /> SEO services?
                    </h2>
                }
                link='/service/web-platforms/seo'
                buttonText='Read more'
            />

            <Grid>
                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <ScrollTextWithImage imageFluid={imageSite} imageLeft>
                    <Divider xsMargin={5} mdMargin={0} />
                    <h2>
                        A to B's completely responsive website snatched the market from his competitors in the
                        roadside assistance sector.
                    </h2>
                    <p>
                        The Element Softworks team understood that many users had to look up to A to
                        B under the extreme circumstances when they had tyre problems mid-journey.
                        This means they would most likely be on their mobile using mobile data on a
                        motorway, where the signal might be unreliable. It was Element Softworks methodology of
                        production clean, ultra-fast websites that are accessible by as many people as possible that put
                        Ben ahead of all his competitors.
                    </p>

                    <p>Want cutting-edge website design? Click the button below.</p>
                    <Link to='/service/web-platforms/web-design'>
                        <Button text='Read more' />
                    </Link>
                </ScrollTextWithImage>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more about our
                        <br /> web development services?
                    </h2>
                }
                link='/service/web-platforms/web-development'
                buttonText='Read more'
            />
            <Img fluid={imageCar} imgStyle={{ objectFit: 'cover' }} />

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        <p>
                            The website was later upgraded with a tyre information database which
                            used a cars number plate to fetch information about wheel sizes and
                            tyres. Users can go through the process of easily finding, ordering and
                            booking fitment of their tyres.
                        </p>

                        <p>
                            If you would like to know more about database integration and APIs,
                            click the button below.
                        </p>
                        <Link to='/service/web-platforms/api-development'>
                            <Button text='Read more' />
                        </Link>

                        <Divider margin={4} />
                        <Divider margin={4} />

                        <div className={styles.fittingContainer}>
                            <h3>Book Your Mobile Fitting</h3>
                            <Link to=''>
                                <Button text='Enter Registration' />
                            </Link>
                            <Divider margin={3} />
                            <Link to=''>
                                <Button text='Search' />
                            </Link>
                        </div>
                        <Divider mdMargin={0} smMargin={3} xsMargin={3} />
                    </Col>
                    <Col xs={12} md={6}>
                        <Img fluid={imagePhones} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} mdMargin={5} />

            <div className={styles.imgContainer}>
                <Img fluid={imagePhotography} imgStyle={{ }} />
            </div>

        </section>
    );
};

export default Branding;
