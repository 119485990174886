import React from 'react';
import { Divider } from '@components/Grid';
import { graphql, Link } from 'gatsby';

import SEO from '@components/Seo';
import Layout from '@components/Layout';
import styles from './styles.module.scss';
import Hero from '@views/case-studies/a-to-b-tyres/Hero';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Description from '@views/case-studies/Description';
import Branding from '@views/case-studies/a-to-b-tyres/Branding';
import ReadMore from '@views/case-studies/ReadMore';

const AToBTyres = ({ data }) => {
    return (
        <Layout>
            <SEO
                title='A to B Tyres • Case Studies'
                image={data?.gundiesPreview?.publicURL}
                description='Ben from A to B Tyres required to be in every search for anything tyre
                            related, within the Ipswich area.'
            />
            <div className={styles.caseStudyWrapper}>
                <Hero image={data?.hero?.childImageSharp?.fluid} />
                <ProjectOverview
                    client='A to B Tyres'
                    name='Website, Photography'
                    backgroundColor='#E53333'
                    description={
                        <p>
                            Ben from A to B Tyres required to be in every search for anything tyre
                            related, within the Ipswich area.
                        </p>
                    }
                />

                <Divider margin={5} />
                <Divider xsMargin={0} mdMargin={5} />

                <Description
                    textLeft={
                        'Within the first month of our SEO campaign with A to B Tyres we had already seen an improvement. By month three we saw an increase of 190% in page hits to the A to B Tyres site.'
                    }
                    textRight={
                        <div>
                            <p>
                                Using the mediums of web & photography we equipped Ben with the
                                beginnings of a brand to advertising his business within the local
                                area.
                            </p>

                            <p>
                                This allowed Ben to take advantage of more risky business decisions,
                                such as adding a mobile fitting divisions that turned out well; not
                                only because of the well-received Google advertising, but because of
                                the incorporated tyre search, purchasing & booking system, we
                                developed into the site.
                            </p>
                        </div>
                    }
                />

                <Branding
                    imageTop={data?.brandingTop?.childImageSharp?.fluid}
                    imageSite={data?.brandingSite?.childImageSharp?.fluid}
                    imageCar={data?.brandingCar?.childImageSharp?.fluid}
                    imagePhones={data?.brandingPhones?.childImageSharp?.fluid}
                    imagePhotography={data?.brandingPhotography?.childImageSharp?.fluid}
                />

                <ReadMore />
            </div>
        </Layout>
    );
};

export default AToBTyres;

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-studies-ab-tyres-hero.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingTop: file(absolutePath: { regex: "/case-studies-ab-tyres-branding-top.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingSite: file(absolutePath: { regex: "/case-studies-ab-tyres-branding-site.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingCar: file(absolutePath: { regex: "/case-studies-ab-tyres-branding-car.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingPhones: file(absolutePath: { regex: "/case-studies-ab-tyres-branding-phones.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingPhotography: file(absolutePath: { regex: "/case-studies-ab-tyres-branding-photography.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
